import styled from 'styled-components'
import { Link } from 'gatsby'

export const EpisodesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  padding: 2rem;
  margin: 3rem 0;
  @media (min-width: 768px) {
    grid-template-columns: 1fr;
    max-width: 50%;
    margin-left: 25%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 1366px) {
    grid-template-columns: 1fr;
  }
`
export const EposideCard = styled(Link)`
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s;
  text-decoration: none;
  :hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
`
export const PodcastContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const PodcastHead = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 5rem 3rem 0rem 2rem;
  border-radius: 0.5rem;
  text-align: center;
`
export const LogoPodcast = styled.img`
  max-height: 30%;
  max-width: 90%;
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 660px) {
    max-height: 30%;
    max-width: 40%;
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1280px) {
    max-height: 50%;
  }
  @media (min-width: 1366px) {
    max-height: 50%;
  }
`

export const PodcastDes = styled.p`
  color: ${({ theme: { colors } }) => colors.black};
  word-wrap: break-word;
  text-align: center;
  margin: auto;
  font-size: 1.2rem;

  @media (min-width: 660px) {
    max-width: 50%;
  }
  @media (min-width: 1280px) {
    max-width: 50%;
  }
  @media (min-width: 1366px) {
    max-width: 50%;
  }
`
export const PodcastTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: normal;
  color: ${({ theme: { colors } }) => colors.primary};
  margin: 0.2rem 0;
`
export const EposideTitle = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.primary};
  margin: 0.2rem 0;
`

export const EposideDes = styled.p`
  font-size: 0.9rem;
  color: ${({ theme: { colors } }) => colors.gray};
  margin: 0.5rem 0;
`
export const EposideLink = styled.div`
  background: ${({ theme: { colors } }) => colors.primary};
  padding: 0.4rem 0.6rem;
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  margin: 0.5rem;
  text-align: center;
  display: inline-block;
`
export const ReadTime = styled.span`
  font-size: 0.9rem;
  color: ${({ theme: { colors } }) => colors.gray2};
`
export const EposideData = styled.div`
  padding: 1rem;
`

export const PostDate = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: ${({ theme: { colors } }) => colors.gray2};
  font-weight: 400;
`
export const PostTags = styled.p`
  margin: 0;
  margin-bottom: 0.6rem;
`

export const PostTag = styled.span`
  font-weight: 400;
  color: ${({ theme: { colors } }) => colors.gray2};
  font-size: 0.75rem;
  margin-right: 1rem;
`
