import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components_v2/seo'
import {
  EpisodesContainer,
  PodcastContainer,
  EposideCard,
  EposideTitle,
  PodcastHead,
  PodcastTitle,
  PodcastDes,
  EposideData,
  EposideDes,
  ReadTime,
  PostDate,
  PostTags,
  PostTag,
  EposideLink,
  LogoPodcast,
} from '../styles/podcast.style'
// import { BlogTitle } from '../components/Blog.style'
import { colors } from '../utils'
import LayoutV2 from '../components_v2/Layout'

const PODCAST_COVER = 'https://alprofile-assest.s3-eu-west-1.amazonaws.com/podcast/podcast_1280_700.png'
const PODCAST_LOGO = 'https://alprofile-assest.s3-eu-west-1.amazonaws.com/podcast/podcast_art_1000_1000.png'
const PODCAST_DESCRIPTION =
  'Zaat podcast, is a series of podcast episodes, in each episode, we discuss a specific topic that is related to web development, the internet, or technology.'
// eslint-disable-next-line no-unused-vars
const Podcast = ({ data }) => {
  const eposides = data.allMarkdownRemark.edges
  return (
    <LayoutV2>
      <SEO
        title="Zaat.dev Podcast"
        image={PODCAST_COVER}
        location="https://zaat.dev/podcast"
        description={PODCAST_DESCRIPTION}
        keywords="podcast, technology,data,education, talks, technology, software"
      />
      <PodcastContainer>
        <PodcastHead>
          <LogoPodcast alt="Podcast cover photo" src={PODCAST_LOGO} />
          <PodcastTitle>Zaat.dev/Podcast</PodcastTitle>
          <PodcastDes>{PODCAST_DESCRIPTION}</PodcastDes>
        </PodcastHead>
        <EpisodesContainer>
          {eposides.map(({ node }) => (
            <EposideCard to={node.frontmatter.slug}>
              <Img fluid={node.frontmatter.image.childImageSharp.thumbnail} />
              <EposideData>
                <EposideTitle>{node.frontmatter.title}</EposideTitle>
                <PostTags>
                  {node.frontmatter.keywords.split(', ').map(tag => (
                    <PostTag>
                      <span
                        css={`
                          color: ${colors.gray};
                        `}
                      >
                        #
                      </span>
                      {tag}
                    </PostTag>
                  ))}
                </PostTags>
                <PostDate>
                  {node.frontmatter.date}
{' '}
-<ReadTime>
{Math.floor(node.frontmatter.duration / 60)} Minutes</ReadTime>
                </PostDate>
                <EposideDes>{node.frontmatter.description}</EposideDes>
              </EposideData>
              <EposideLink> Listen now...</EposideLink>
            </EposideCard>
          ))}
        </EpisodesContainer>
      </PodcastContainer>
    </LayoutV2>
  )
}

export default Podcast

export const podcastPageQuery = graphql`
  query podcastPageQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "podcast" } } }) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            description
            keywords
            slug
            duration
            image {
              childImageSharp {
                thumbnail: fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
